import { useState, useContext, createContext } from "react";
import Cookies from "js-cookie";

const consentAccepted = () => Cookies.get("EskaCookieConsent") === true;

// const isLangSet = () => Cookies.get("PreferredLang") === "hu" || Cookies.get("PreferredLang") === "en";

const LangContext = createContext();

export const useLangContext = () => useContext(LangContext);

export const LangWrapper = ({ children }) => {
    const [currentLang, setCurrentLang] = useState(Cookies.get("PreferredLang") ? Cookies.get("PreferredLang") : "en");

    const setLang = (e) => {
        if (Cookies.get("EskaCookieConsent") === "true") {
            Cookies.remove("PreferredLang");
            Cookies.set("PreferredLang", e.target.value, { expires: 100 });
            // console.log("new lang cookie has been set")
        };
        // console.log("setLang runs")
        setCurrentLang(e.target.value)
    }

    return (
        <LangContext.Provider value={{ currentLang, setLang }}>
            {children}
        </LangContext.Provider>
    )
} 

