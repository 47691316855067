export const langObj = {
    title: {
        "hu": "TÉL",
        "en": "WINTER"
    }
};

export const currentSeason = {
    fall: {
        "hu": "ŐSZ",
        "en": "FALL"
    },
    spring: {
        "hu": "TAVASZ",
        "en": "SPRING"
    },
    winter: {
        "hu": "TÉL",
        "en": "WINTER"
    },
    summer: {
        "hu": "NYÁR",
        "en": "SUMMER"
    }
};

export const legacyText = {
    "hu": "Elevenítse fel előző kollekciók hangulatát és ossza meg velünk",
    "en": "Evoke the impressions of outfits already passed and tell us about them."
};