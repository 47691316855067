import React, { useRef, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useThemeContext } from "../../contprovider/themeProvider";
import { useLangContext } from "../../contprovider/langProvider";
import { useOpenContext } from "../../contprovider/openProvider";
import { HiOutlineMenuAlt4 } from 'react-icons/hi';
import { RiCloseLine } from "react-icons/ri";
import { motion, AnimatePresence } from "framer-motion";
import useScrollPosition from "../../hooks/useScrollPosition";
import { langObj } from "../../languages/Header";
import { IOSSwitch } from "./SwitchStyles";
import "./Header.css";


function Header() {

    const { isDarkTheme, onToggleTheme } = useThemeContext();
    const { currentLang } = useLangContext();
    const { open, setOpen } = useOpenContext();
    const headerRef = useRef(null);

    const scrollYPos = useScrollPosition();
    const animateFrom = {opacity: 0, y: -40};
    const animateTo = {opacity: 1, y: 0};
    const exitAnimation = { opacity: 0, y: -40 };
    const isInNullPosition = scrollYPos <= 10;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if(headerRef.current && !headerRef.current.contains(event.target)) {
                setOpen(false);
            }
        };

        document.addEventListener("touchstart", handleClickOutside);

        return () => document.removeEventListener("touchstart", handleClickOutside);
    }, []);
    
    return (
        <>
            <header 
            className={`header-container ${isDarkTheme ? "header-night" : "header-light"} ${isInNullPosition ? "header-at-null" : "header-not-at-null"}`}
            ref={headerRef}
            >
                <div className={`logo-container ${isDarkTheme ? "logo-container-dark" : "logo-container-light"}`}>
                    
                        <div className="logo-holder">
                        <Link to="/" className="return-home-button">
                            <h4 className="brand-logo">eska</h4>
                            <p className="brand-logo-ext">collections</p>
                        </Link>
                        </div>
                    
                </div>
                <div className={`navbar-container ${isDarkTheme ? "navbar-cont-dark" : "navbar-cont-light"}`}>
                    <div className="navbar-section navbar-section-mobile">
                        <AnimatePresence>
                        {open && (
                            <motion.ul 
                            className={`navbar navbar-mobile ${isDarkTheme ? "nav-night" : "nav-light"}`}
                            exit={exitAnimation}
                            >
                                <motion.li 
                                className="nav theme-switch-mobile"
                                initial={animateFrom}
                                animate={animateTo}
                                
                                >
                                    <IOSSwitch 
                                    checked={isDarkTheme} 
                                    id="theme-switch-mobile-button" 
                                    onChange={onToggleTheme}
                                    size="medium"
                                    style={{
                                        marginRight: "0vw"
                                    }}
                                    />
                                </motion.li>
                            
                                <motion.li
                                initial={animateFrom}
                                animate={animateTo}
                                
                                >
                                    <NavLink 
                                    to="/aboutus"
                                    className={({isActive}) => isActive ? `active nav` : `inactive nav`}
                                    onClick={() => setOpen(false)}
                                    >
                                        {langObj.aboutUs[currentLang]}
                                    </NavLink>
                                </motion.li>
                                <motion.li
                                initial={animateFrom}
                                animate={animateTo}
                                
                                >
                                    <NavLink
                                    to="/collections"
                                    className={({isActive}) => isActive ? "active nav": "inactive nav"}
                                    onClick={() => setOpen(false)}
                                    >
                                        {langObj.collections[currentLang]} 
                                    </NavLink>
                                </motion.li>
                                <motion.li 
                                initial={animateFrom}
                                animate={animateTo}
                                                           
                                >
                                    <NavLink
                                    to="/contact"
                                    className={({isActive}) => isActive ? "active nav": "inactive nav"}
                                    onClick={() => setOpen(false)}
                                    >
                                        {langObj.contact[currentLang]}
                                    </NavLink>
                                </motion.li>
                            
                            </motion.ul>
                        )}
                        </AnimatePresence> 
                        <ul className={`navbar navbar-desktop`}>
                            <li 
                          
                            className="nav theme theme_holder"
                            >
                                
                                
                                <IOSSwitch
                                checked={isDarkTheme} 
                                id="theme-switch" 
                                onChange={onToggleTheme} 
                                size="big"
                                
                                />
                                
                            </li>
                            
                            <li><NavLink 
                                to="/aboutus"
                                className={({isActive}) => isActive ? `active nav` : `inactive nav`}
                                >
                                    {langObj.aboutUs[currentLang]}
                                </NavLink>
                            </li>                           

                            <li><NavLink
                                to="/collections"
                                className={({isActive}) => isActive ? "active nav": "inactive nav"}
                                >
                                    {langObj.collections[currentLang]} 
                                </NavLink>
                            </li>
                            <li>
                                <NavLink 
                                to="/contact"
                                className={({isActive}) => isActive ? "active nav": "inactive nav"}
                                >
                                    {langObj.contact[currentLang]}
                                </NavLink>
                            </li>
                            
                        </ul>
                        
                        <div 
                        className="hamburger-container"
                        
                        >
                            { open ? 
                            (<RiCloseLine 
                            className="hamburger-icon"
                            onClick={() => setOpen(!open)}
                            />) : 
                            (<HiOutlineMenuAlt4
                            onClick={() => setOpen(!open)}
                            className="close-icon"
                            />) }
                        </div>
                    </div>
                </div>
            </header>
            <Outlet />
            <style jsx>{`
                body {
                    background-color: ${isDarkTheme ? "rgba(0, 0, 0, 0.7)" : "white"};
                    transition: background-color 1s;
                }

                .hamburger-icon {
                    color: ${isDarkTheme ? "white" : "black"};
                }

                .close-icon {
                    color: ${isDarkTheme ? "white" : "black"};
                }

                .navbar-desktop .inactive:hover {
                    text-decoration: underline;
                    color: ${isDarkTheme ? "aqua" : "blue"};
                }

                .navbar-desktop .active:hover {
                    text-decoration: none;

                }
            `}</style>
        </>
    )

}

export default Header;