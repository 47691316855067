import React, {Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeWrapper } from './contprovider/themeProvider';
import { LangWrapper } from './contprovider/langProvider';
import { OpenWrapper } from './contprovider/openProvider';
import { TestProxyRequest } from './api/Reqests';
/// Import fonts here
import './fonts/Infinita.css';
import './fonts/Raleway.css';
import './fonts/Playfair.css';
import './fonts/Oswald.css';
import './fonts/Lobster.css';
import './fonts/Quicksand.css';
///
/// Import custom components here
// import WelcomePage from './components/welcomepage/WelcomePage'
// import AboutUs from './components/aboutus/Aboutus';
import MySlider from './components/collections/Carousel';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import CustomCookieOverlay from './components/cookieconsent/CustomCookie';
/// Import photos
import * as fallPics from './components/collections/img_legacy/fall';
import * as wintPics from './components/collections/img_legacy/winter';
import * as sprPics from './components/collections/img_legacy/spring';

/// Context providers

///
/// Language objects
import { currentSeason } from './languages/Carousel';
///

const WelcomePage = React.lazy(() => import('./components/welcomepage/WelcomePage'));
const AboutUs = React.lazy(() => import('./components/aboutus/Aboutus'));
const ExpiredSeason = React.lazy(() => import('./components/expired/fall/ExpiredSeason'));
const Contact = React.lazy(() => import('./components/contact/Contact'));

const fallP = Object.values(fallPics);
const wintP = Object.values(wintPics);
const sprP = Object.values(sprPics)

function App() {

  useEffect(() => {
    TestProxyRequest();
  }, []);

  return (
    <BrowserRouter>
      <ThemeWrapper>
      <LangWrapper>
      <OpenWrapper>
          <CustomCookieOverlay />
          <Suspense fallback={<div></div>}>
            <Routes>
              <Route path="/" element={ <Header /> }>
              <Route path="/" element={ <Footer /> }>
                <Route path="/" element={<WelcomePage /> } />
                <Route path="/collections" element={ <MySlider />} />
                <Route path="/aboutus" element={ <AboutUs /> } />
                <Route path="/contact" element={<Contact />} />
                <Route path="/collections/spring" element={ <ExpiredSeason images={sprP} season={currentSeason.spring} prev={false} next={"/collections/winter"} /> } />
                <Route path="/collections/winter" element={ <ExpiredSeason images={wintP}  season={currentSeason.winter} prev={"/collections/spring"} next={"/collections/fall"} /> }/>
                <Route path="/collections/fall" element={ <ExpiredSeason images={fallP}  season={currentSeason.fall} prev={"/collections/winter"} next={false}/> } />
              </Route>
              </Route>
            </Routes>
          </Suspense>
      </OpenWrapper>
      </LangWrapper>
      </ThemeWrapper>
    </BrowserRouter>
  )
}

export default App;
