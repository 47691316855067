import React, { useState, useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import { useThemeContext } from "../../../contprovider/themeProvider";
import "./seasonCard.css";
import { NavLink } from "react-router-dom";

function SeasonCard({ pics, season, navigateTo }) {

    const { isDarkTheme } = useThemeContext();
    
    const boxVariant1 = {
        visible: { x: 0, transition: { duration: 1}, },
        hidden: { x: "-20%" }
    };
    const boxVariant2 = {
        visible: { opacity: 1,  transition: {duration: 2.5}},
        hidden: { opacity: 0,  }
    };
    ////
    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if(inView) {
            control.start("visible");
        }
    });
    ////
    ////
    const control2 = useAnimation();
    const [ref2, inView2] = useInView();

    useEffect(() => {
        if(inView2) {
            control2.start("visible");
        }
    });
    ////

    return (
        <motion.div 
        className="seasoncard-container"
        initial="hidden"
        animate={control}
        ref={ref}
        variants={boxVariant1}
        >
            <div
            className="seasoncard-inner-container"
            >
                <section 
                className="seasoncard-img-container seasonone"
                style={{
                    backgroundImage: `url(${pics[0]})`
                }}
                >
                   
                </section>
                <section 
                className="seasoncard-img-container seasontwo"
                style={{
                    backgroundImage: `url(${pics[1]})`
                }}
                >
                    
                </section>
                <section 
                className="seasoncard-img-container seasonthree"
                style={{
                    backgroundImage: `url(${pics[2]})`
                }}
                >

                </section>
                <section 
                className="seasoncard-img-container seasonfour"
                style={{
                    backgroundImage: `url(${pics[3]})`
                }}
                >

                </section>
            </div>
            
                <motion.div 
                className="textbox"
                initial="hidden"
                animate={control2}
                ref={ref2}
                variants={boxVariant2}
              
                >
                    <Typography 
                    variant="h4"
                    id="postertext"
                    style={{
                        color: `${isDarkTheme ? "var(--black-theme-font)" : "white"}`
                    }}
                    >
                        {season}
                    </Typography>
                    <Button 
                    variant="contained"
                    id="preview-button"
                    >

                        <NavLink 
                        to={`/collections/${navigateTo}`}
                        style={{
                            width: "100%",
                            height: "100%",
                            textDecoration: "none",
                            color: "white"
                        }}
                        >
                            Preview
                        </NavLink>

                    </Button>
                </motion.div>
        
        </motion.div>
    )

};

export default SeasonCard
