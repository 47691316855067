
import API from "./client";
import axios from "axios";
// const API_ENDPOINT = "https://localhost:5000/api/contact"

export const PostRequest = async(name, email, message) => {
    try {
        // return {"message": "Email sent successfully"};
        const response = await API.post(`contact`, {name, email, message});
        const responseData = await response.data;
        return responseData;
    } catch(e) {
        console.log(e)
        console.log(e.message);
        return { error: "An error occured while sending the request" };
    }

}

export const TestProxyRequest = async() => {
    try {
        console.log("Testing proxy");
        const response = await API.get(`test`);
        const responseData = await response.data;
        return responseData
    } catch(e) {
        console.log(e)
        return { error: "An error occured while testing proxy" };
    }
}