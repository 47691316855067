import { useState, useContext, createContext } from "react";
import Cookies from "js-cookie";

//const consentAccepted = () => Cookies.get("EskaCookieConsent") === true;

const ThemeContext = createContext();

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeWrapper = ({ children }) => {
  
  const [theme, setTheme] = useState(Cookies.get("PreferredTheme") ? Cookies.get("PreferredTheme") : "light");

  const onToggleTheme = () => {
    let arg = theme === "light" ? "dark" : "light";
    if (Cookies.get("EskaCookieConsent") === "true") {
      console.log("Cookie is set")
      Cookies.remove("PreferredTheme");
      Cookies.set("PreferredTheme", arg, { expires: 100 });
    }
    setTheme(arg);
  }

  const isDarkTheme = (theme === "dark");

  return (
    <ThemeContext.Provider value={{ isDarkTheme, onToggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}