import { useState, createContext, useContext } from "react";

const OpenContext = createContext();

export const useOpenContext = () => useContext(OpenContext);

export const OpenWrapper = ({ children }) => {

    const [open, setOpen] = useState(false);

    return (

        <OpenContext.Provider value={{ open, setOpen }}>
            {children}
        </OpenContext.Provider>
    )
};