import React from "react";
import "./Footer.css";
import { Outlet } from "react-router-dom";
import { BsFacebook } from "react-icons/bs"
import { BsYoutube } from "react-icons/bs";
import { BsGithub } from "react-icons/bs";
import { useThemeContext } from "../../contprovider/themeProvider";
import { useLangContext } from "../../contprovider/langProvider";
import { useOpenContext } from '../../contprovider/openProvider';
import { TextField, MenuItem } from "@mui/material";
import { youtubeLogo, facebookLogo, githubLogo, turulLogo } from "./img";


function Footer() {

    const { isDarkTheme } = useThemeContext();
    const { currentLang, setLang } = useLangContext();
    const { open } = useOpenContext();

    return (
        <>
            <Outlet />
            <div className={`footer-container ${open ? "footer-container-blurred" : ""}`}>
                <div 
                className="footer-inner-container"
                style={{
                    borderTop: `${isDarkTheme ? "1px solid white" : "1px solid black"}`,
                }}
                >
                    <div className="copyright-container">
                        <div className="copyright-inner-container">
                            <TextField
                            id="outlined-select-language"
                            select
                            label={currentLang === "en" ? "Nyelv" : "Language"}
                            defaultValue="en"
                            helperText={currentLang === "en" ? "Nyelv kiválasztása" : "Select language"}
                            value={currentLang}
                            onChange={setLang}
                            
                            >
                                <MenuItem
                                key="1"
                                value="en"
                                >
                                    EN
                                </MenuItem>
                                <MenuItem 
                                key="2"
                                value="hu"
                                >
                                    HU
                                </MenuItem>
                            </TextField>
                        </div>
                    </div>
                    <div className="reference-container">
                        <a
                        className="turul-anchor"
                        href="https://www.textilturul.eu/profile-45232-monroe-divat"
                        target="_blank"
                        >

                            <img 
                            className="turul-logo"
                            src={turulLogo}
                            />
                        </a>
                      
                    </div>
                </div>
                <section className="ref-logo-container">
                    <div 
                    className="ref-card first"
                                
                    >
                        <a 
                        href="https://www.facebook.com/p/ESKA-Collections-100055086832249/"
                        target="_blank"
                        className="ref-anchor"
                        aria-label="Store's facebook account."
                        rel="noreferrer"
                        >
                            <img 
                            src={facebookLogo}
                            className="ref-icon"
                            />
                        </a>
                    </div>
                    <div 
                    className="ref-card second"            
                    >
                        <a 
                        href="https://www.youtube.com/@eskacollections6954"
                        target="_blank"
                        className="ref-anchor"
                        aria-label="Store's youtube channel."
                        rel="noreferrer"
                        >
                            <img 
                            src={youtubeLogo}
                            className="ref-icon"/>
                        </a>
                    </div>
                    <div
                    className="ref-card github"            
                    >
                        <a 
                            href="https://github.com/shermmminator"
                            target="_blank"
                            className="ref-anchor"
                            aria-label="Creator's github page."
                            rel="noreferrer"
                        >
                            <img 
                            src={githubLogo}
                            className="ref-icon"/>
                        </a>
                    </div>
                </section>
            </div>
            <style jsx>
                {`
                .ref-card .ref-anchor,
                .radio-label {
                    color: ${isDarkTheme ? "white" : "black"};
                }

                
                `}
            </style>
        </>
    )
}

export default Footer;