import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import "./CustomCookie.css";
import { Typography } from "@mui/material"; 
import Cookies from "js-cookie";

function CustomCookieOverlay() {

    const [cookieAccepted, setCookieAccepted] = useState(Cookies.get("EskaCookieConsent"));
    const [showContent, setShowContent] = useState(false);

    const handleAccept = () => {
        Cookies.remove("PreferredTheme");
        Cookies.remove("PreferredLang");
        Cookies.set("PreferredTheme", "light", { expires: 100 });

        Cookies.set("PreferredLang", "en", { expires: 100 });
        setCookieAccepted(true);
        return
    };

    const handleDecline = () => {
        try {

            Cookies.remove("PreferredTheme");
            Cookies.remove("PreferredLang");
            setCookieAccepted(false);
        } catch(e) {
            console.log(e)
        }
        return
    }

    const displayOrNot = () => {
        return showContent && cookieAccepted === undefined;
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowContent(true);
        }, 3000);
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <>
            {displayOrNot() && (
                <div
                className="cookie-container"
                >
                    <CookieConsent
                    location="bottom"
                    buttonText="Accept"
                    enableDeclineButton
                    declineButtonText="Decline"
                    cookieName="EskaCookieConsent"
                    style={{
                        background: "#2B373B",
                        padding: "20px",
                        color: "#ffffff",
                        fontSize: "0.9vw",
                        
                    }}
                    buttonClasses="accept-button"
                    declineButtonClasses="decline-button"
                    expires={90}
                    onAccept={handleAccept}
                    onDecline={handleDecline}
                    >
                        <Typography 
                        variant="h3"
                        className="cookie-title"
                        >
                        
                            Cookies Consent
                        </Typography>
                        <Typography
                        className="cookie-text"
                        >
                            This website uses cookies to enhance user experience.
                        </Typography>
                    </CookieConsent>
                </div>
            )}
        </>
        
         
    )
};

export default CustomCookieOverlay