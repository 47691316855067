export const langObj = {
    aboutUs: {
        "hu": "Rólunk",
        "en": "About us"
    },

    collections: {
        "hu": "Kollekciók",
        "en": "Collections"
    },

    contact: {
        "hu": "Kontakt",
        "en": "Contact"
    }
}